import React, { useEffect, useState } from "react";
import axios from "axios";

import { Row, Col } from "react-bootstrap";
import { Form, InputGroup, Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

export default function TransactionAdd() {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({
    success: "",
    message: "",
  });

  // Pre-populate select options
  const [users, setUsers] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [counterparties, setCounterparties] = React.useState([]);
  const [paymentCards, setPaymentCards] = React.useState([]);

  // Dynamic fields
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalPayment, setTotalPayment] = React.useState(0);

  const [formSplitIsDefault, setFormSplitIsDefault] = React.useState(true);
  const [formSplitBy, setFormSplitBy] = React.useState("percent");
  const [formValues, setFormValues] = React.useState({});
  const [formFieldsCategories, setFormFieldsCategories] = React.useState([
    {
      category: "",
      amount: "",
    },
  ]);
  const [formFieldsPayments, setFormFieldsPayments] = React.useState([
    {
      paymentCard: "",
      amount: "",
    },
  ]);
  const [formFieldsSplits, setFormFieldsSplits] = React.useState([
    {
      user: "",
      value: "",
    },
  ]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + "/users/").then((res) => {
      setUsers(res.data.map(({ _id, name }) => ({ label: name, value: _id })));
    });

    axios.get(process.env.REACT_APP_API_URL + "/categories/").then((res) => {
      setCategories(
        res.data.map(({ _id, name }) => ({ label: name, value: _id }))
      );
    });

    axios
      .get(process.env.REACT_APP_API_URL + "/counterparties/")
      .then((res) => {
        setCounterparties(
          res.data.map(({ _id, name }) => ({ label: name, value: _id }))
        );
      });

    axios
      .get(process.env.REACT_APP_API_URL + "/users/paymentcards")
      .then((res) => {
        setPaymentCards(
          res.data.map(({ _id, description }) => ({
            label: description,
            value: _id,
          }))
        );
        setLoading(false);
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    let combinedFormData = formValues;
    combinedFormData.categories = formFieldsCategories;
    combinedFormData.payments = formFieldsPayments;

    if (formSplitIsDefault) {
      //TODO: Setup default in backend
      combinedFormData.splitting = {
        by: "percent",
        splits: [
          { user: "61e22695c49611001a5170fb", value: 50 },
          { user: "61e226a4c49611001a517100", value: 50 },
        ],
      };
    } else {
      combinedFormData.splitting = {
        by: formSplitBy,
        splits: formFieldsSplits,
      };
    }

    await axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/transactions",
      data: formValues,
    })
      .then((res) => {
        reset();
        setMessage({
          success: true,
          message: "✅ Transaction created",
        });
        setTimeout(() => {
          setMessage({ message: "" });
        }, 3000);
      })
      .catch((err) => {
        reset();
        setMessage({
          success: false,
          message: "⛔️ " + err.message,
        });
      });
  };

  const handleCategoriesChange = (index, e) => {
    let newCategories = [...formFieldsCategories];
    newCategories[index][e.target.name] = e.target.value;
    setFormFieldsCategories(newCategories);

    if (e.target.name == "amount") {
      setTotalAmount(
        formFieldsCategories
          .map((category) => Number(category.amount))
          .reduce((a, b) => a + b)
          .toFixed(2)
      );
    }
  };

  const handlePaymentsChange = (index, e) => {
    let newPayments = [...formFieldsPayments];
    newPayments[index][e.target.name] = e.target.value;
    setFormFieldsPayments(newPayments);

    if (e.target.name == "amount") {
      setTotalPayment(
        formFieldsPayments
          .map((payment) => Number(payment.amount))
          .reduce((a, b) => a + b)
          .toFixed(2)
      );
    }
  };

  const handleSplitsChange = (index, e) => {
    let newSplits = [...formFieldsSplits];
    newSplits[index][e.target.name] = e.target.value;
    setFormFieldsSplits(newSplits);
  };

  const reset = async () => {
    setFormSplitIsDefault(true);
    setFormSplitBy("percent");
    setTotalAmount(0);
    setTotalPayment(0);
    setFormFieldsCategories([
      {
        category: "",
        amount: "",
      },
    ]);
    setFormFieldsPayments([
      {
        paymentCard: "",
        amount: "",
      },
    ]);
    setFormFieldsSplits([
      {
        user: "",
        value: "",
      },
    ]);
  };

  if (loading)
    return (
      <div className="d-flex justify-content-center mt-5 mb-5">
        <Spinner animation="border" role="status" variant="secondary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );

  return (
    <div>
      <Row>
        <Col xs={12} className={message.message ? "d-block" : "d-none"}>
          <Alert
            variant={message.success ? "success" : "danger"}
            onClose={() => setMessage({ message: "" })}
            dismissible
          >
            {message.message}
          </Alert>
        </Col>
        <Col>
          <h3 className="mb-4">Create Transaction</h3>
        </Col>
        <Col className="d-none d-lg-block text-end">
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Col>
      </Row>
      <Form>
        <Row xs={1} lg={2} xxl={3} className="g-3">
          <Col>
            <Card>
              <Card.Header>Basics</Card.Header>
              <Card.Body>
                <Form.Group className="mb-2" controlId="form-Basics-User">
                  <Form.Label>Who are you?</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        createdBy: e.target.value,
                      })
                    }
                  >
                    <option></option>
                    {users.map((user) => (
                      <option key={user.value} value={user.value}>
                        {user.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2" controlId="form-Basics-Date">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) =>
                      setFormValues({ ...formValues, date: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="form-Basics-Counterparty"
                >
                  <Form.Label>Shop</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        counterparty: e.target.value,
                      })
                    }
                  >
                    <option></option>
                    {counterparties.map((counterparty) => (
                      <option
                        key={counterparty.value}
                        value={counterparty.value}
                      >
                        {counterparty.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="form-Basics-Description"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    value={formValues.description || ""}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        description: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
          {/* TODO: Remove fields if turn back to null */}
          <Col>
            <Card>
              <Card.Header>Coverage</Card.Header>
              <Card.Body>
                <Form.Group className="mb-2" controlId="form-Coverage-Start">
                  <Form.Label>Start</Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        "coverage.start": e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-2" controlId="form-Coverage-End">
                  <Form.Label>End</Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        "coverage.end": e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>Categories</Card.Header>
              <Card.Body>
                {formFieldsCategories.map((element, index) => (
                  <Form.Group
                    className="mb-2"
                    controlId="form-Categories"
                    key={index}
                  >
                    <InputGroup className="mb-2">
                      <Form.Select
                        name="category"
                        value={element.category}
                        className="w-50"
                        onChange={(e) => handleCategoriesChange(index, e)}
                      >
                        <option></option>
                        {categories.map((category) => (
                          <option key={category.value} value={category.value}>
                            {category.label}
                          </option>
                        ))}
                      </Form.Select>
                      <InputGroup.Text>£</InputGroup.Text>
                      <Form.Control
                        name="amount"
                        value={element.amount}
                        onChange={(e) => handleCategoriesChange(index, e)}
                      />
                      {!index ? (
                        <Button
                          variant="success"
                          onClick={() =>
                            setFormFieldsCategories([
                              ...formFieldsCategories,
                              { category: "", amount: "" },
                            ])
                          }
                          className="font-monospace"
                        >
                          +
                        </Button>
                      ) : (
                        <Button
                          variant="danger"
                          onClick={() => {
                            let newCategories = [...formFieldsCategories];
                            newCategories.splice(index, 1);
                            setFormFieldsCategories(newCategories);
                          }}
                          className="font-monospace"
                        >
                          -
                        </Button>
                      )}
                    </InputGroup>
                  </Form.Group>
                ))}
              </Card.Body>
              <Card.Footer className="text-end">
                Total: <span className="fw-bold">£{totalAmount}</span>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>Splitting</Card.Header>
              <Card.Body>
                <Form.Group className="mb-2">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Default"
                    checked={formSplitIsDefault}
                    onChange={(e) => setFormSplitIsDefault(e.target.checked)}
                  />
                  <Form.Text muted>
                    Select default splitting for 50% each for Tim and Dave.
                  </Form.Text>
                </Form.Group>

                <div className={formSplitIsDefault ? "d-none" : "d-block"}>
                  <Form.Group className="mb-2" controlId="formSplittingBy">
                    <Form.Check
                      inline
                      label="Percentage"
                      name="formSplittingBy"
                      value="percent"
                      type="radio"
                      defaultChecked="true"
                      id="formSplittingByPercent"
                      onChange={(e) => setFormSplitBy(e.target.value)}
                    />
                    <Form.Check
                      inline
                      label="Exact Amount"
                      name="formSplittingBy"
                      value="amount"
                      type="radio"
                      id="formSplittingByAmount"
                      onChange={(e) => setFormSplitBy(e.target.value)}
                    />
                  </Form.Group>

                  {formFieldsSplits.map((element, index) => (
                    <div
                      key="amount"
                      className={formSplitBy == "amount" ? "d-block" : "d-none"}
                    >
                      <Form.Group
                        className="mb-2"
                        controlId="formSplittingValue"
                        key={index}
                      >
                        <InputGroup className="mb-2">
                          <Form.Select
                            name="user"
                            value={element.user}
                            onChange={(e) => handleSplitsChange(index, e)}
                          >
                            <option></option>
                            {users.map((user) => (
                              <option key={user.value} value={user.value}>
                                {user.label}
                              </option>
                            ))}
                          </Form.Select>
                          <InputGroup.Text>£</InputGroup.Text>
                          <Form.Control
                            name="value"
                            value={element.value}
                            onChange={(e) => handleSplitsChange(index, e)}
                          />
                          {!index ? (
                            <Button
                              variant="success"
                              onClick={() =>
                                setFormFieldsSplits([
                                  ...formFieldsSplits,
                                  { user: "", value: "" },
                                ])
                              }
                              className="font-monospace"
                            >
                              +
                            </Button>
                          ) : (
                            <Button
                              variant="danger"
                              onClick={() => {
                                let newSplits = [...formFieldsSplits];
                                newSplits.splice(index, 1);
                                setFormFieldsSplits(newSplits);
                              }}
                              className="font-monospace"
                            >
                              -
                            </Button>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </div>
                  ))}

                  {formFieldsSplits.map((element, index) => (
                    <div
                      key="percent"
                      className={
                        formSplitBy == "percent" ? "d-block" : "d-none"
                      }
                    >
                      <Form.Group
                        className="mb-2"
                        controlId="formSplittingValue"
                        key={index}
                      >
                        <InputGroup className="mb-2">
                          <Form.Select
                            name="user"
                            value={element.user}
                            onChange={(e) => handleSplitsChange(index, e)}
                          >
                            <option></option>
                            {users.map((user) => (
                              <option key={user.value} value={user.value}>
                                {user.label}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            name="value"
                            value={element.value}
                            onChange={(e) => handleSplitsChange(index, e)}
                          />
                          <InputGroup.Text>%</InputGroup.Text>
                          {!index ? (
                            <Button
                              variant="success"
                              onClick={() =>
                                setFormFieldsSplits([
                                  ...formFieldsSplits,
                                  { user: "", value: "" },
                                ])
                              }
                              className="font-monospace"
                            >
                              +
                            </Button>
                          ) : (
                            <Button
                              variant="danger"
                              onClick={() => {
                                let newSplits = [...formFieldsSplits];
                                newSplits.splice(index, 1);
                                setFormFieldsSplits(newSplits);
                              }}
                              className="font-monospace"
                            >
                              -
                            </Button>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </div>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>Payments</Card.Header>
              <Card.Body>
                {formFieldsPayments.map((element, index) => (
                  <Form.Group
                    className="mb-2"
                    controlId="form-Payments-Card"
                    key={index}
                  >
                    <InputGroup className="mb-2">
                      <Form.Select
                        name="paymentCard"
                        value={element.paymentCard}
                        onChange={(e) => handlePaymentsChange(index, e)}
                      >
                        <option></option>
                        {paymentCards.map((paymentCard) => (
                          <option
                            key={paymentCard.value}
                            value={paymentCard.value}
                          >
                            {paymentCard.label}
                          </option>
                        ))}
                      </Form.Select>
                      <InputGroup.Text>£</InputGroup.Text>
                      <Form.Control
                        name="amount"
                        value={element.amount}
                        onChange={(e) => handlePaymentsChange(index, e)}
                      />
                      {!index ? (
                        <Button
                          variant="success"
                          onClick={() =>
                            setFormFieldsPayments([
                              ...formFieldsPayments,
                              { paymentCard: "", amount: "" },
                            ])
                          }
                          className="font-monospace"
                        >
                          +
                        </Button>
                      ) : (
                        <Button
                          variant="danger"
                          onClick={() => {
                            let newPayments = [...formFieldsPayments];
                            newPayments.splice(index, 1);
                            setFormFieldsPayments(newPayments);
                          }}
                          className="font-monospace"
                        >
                          -
                        </Button>
                      )}
                    </InputGroup>
                  </Form.Group>
                ))}
              </Card.Body>
              <Card.Footer className="text-end">
                Total: <span className="fw-bold">£{totalPayment}</span>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>Attachments</Card.Header>
              <Card.Body>
                <Form.Group
                  className="mb-2"
                  controlId="form-Attachments-Description"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    value={formValues["attachments.0.description"] || ""}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        "attachments.0.description": e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-2" controlId="form-Attachments-Path">
                  <Form.Label>File Path</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={formValues["attachments.0.path"] || ""}
                    rows={2}
                    className="font-monospace"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        "attachments.0.path": e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
          <Col className="d-lg-none">
            <div className="d-grid">
              <Button variant="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
