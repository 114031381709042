import React, { Component, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Badge from "react-bootstrap/Badge";

// TEMPORARY WORKAROUND
import { reviewUser } from "../../static";

export default function ReviewList() {
  const history = useHistory();
  const [message, setMessage] = useState({
    success: false,
    message: "",
  });
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (history.location.state) {
      setMessage({
        success: history.location.state.success,
        message: history.location.state.message,
      });
      setTimeout(() => {
        setMessage({ message: "" });
      }, 3000);
    }

    axios
      .get(process.env.REACT_APP_API_URL + "/review/pending/" + reviewUser())
      .then((res) => {
        setTransactions(res.data);
        setLoading(false);
      });
  }, []);

  if (loading)
    return (
      <div className="d-flex justify-content-center mt-5 mb-5">
        <Spinner animation="border" role="status" variant="secondary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );

  if (transactions.length == 0)
    return (
      <div>
        <h3>
          Pending Reviews{" "}
          <Badge bg="secondary" pill="true">
            {transactions.length}
          </Badge>
        </h3>
        <Alert variant="success" className="mt-4">
          <Alert.Heading>✅ All Clear!</Alert.Heading>
          <hr />
          <p className="mb-0">You've reviewed all transactions.</p>
        </Alert>
      </div>
    );

  return (
    <div>
      <Row>
        <Col xs={12} className={message.message ? "d-block" : "d-none"}>
          <Alert
            variant={message.success ? "success" : "danger"}
            onClose={() => setMessage({ message: "" })}
            dismissible
          >
            {message.message}
          </Alert>
        </Col>
        <Col>
          <h3>
            Pending Reviews{" "}
            <Badge bg="secondary" pill="true">
              {transactions.length}
            </Badge>
          </h3>
        </Col>
      </Row>

      <Row>
        {transactions.map((transaction) => (
          <Col
            key={transaction._id}
            xs={12}
            md={6}
            lg={4}
            xxl={3}
            className="g-3"
          >
            <Card key={transaction._id}>
              <Card.Body>
                <Card.Title>{transaction.counterparty.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {transaction.description}
                </Card.Subtitle>
                <Button variant="primary" href={"/review/" + transaction._id}>
                  Review
                </Button>
              </Card.Body>
              <Card.Footer>
                <Row md={2}>
                  <Col className="fw-bold">
                    £
                    {transaction.categories
                      .map((category) => category.amount)
                      .reduce((a, b) => a + b)
                      .toFixed(2)}
                  </Col>
                  <Col className="text-muted text-end">
                    {new Date(transaction.date).toLocaleDateString()}
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}
