import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function NavBar() {
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/">💸 BBK Expenses</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link href="/transaction/add">Create</Nav.Link>
          <Nav.Link href="/review/pending">Review</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}
