import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";

import { Row, Col } from "react-bootstrap";
import { Form, InputGroup, Button } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Tabs, Tab } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

// TEMPORARY WORKAROUND
import { paymentUser, reviewUser } from "../../static";

export default function Review() {
  const history = useHistory();
  const { transaction_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({
    success: "",
    message: "",
  });
  const [transaction, setTransaction] = useState("");
  const [isCommenting, setIsCommenting] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/transactions/" + transaction_id)
      .then((res) => {
        setTransaction(res.data);
        setLoading(false);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    let review = {
      transaction: transaction_id,
      user: reviewUser(),
    };

    if (event.target.name == "approve") {
      review = { ...review, approved: true };
      const message = "✅ Transaction approved";
    }

    if (event.target.name == "comment") {
      review = { ...review, approved: false, comment: comment };
      const message = "✅ Comment sent";
    }
    console.log(review);

    axios
      .post(process.env.REACT_APP_API_URL + "/review", review)
      .then((res) => {
        let url = "/review/pending";
        history.push({
          pathname: "/review/pending",
          state: {
            success: true,
            message: message,
          },
        });
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        setMessage({
          success: false,
          message: "⛔️ " + err.message,
        });
      });
  };

  if (loading)
    return (
      <div className="d-flex justify-content-center mt-5 mb-5">
        <Spinner animation="border" role="status" variant="secondary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );

  return (
    <div>
      <Row>
        <Col xs={12} className={message.message ? "d-block" : "d-none"}>
          <Alert
            variant={message.success ? "success" : "danger"}
            onClose={() => setMessage({ message: "" })}
            dismissible
          >
            {message.message}
          </Alert>
        </Col>
        <Col className="mb-4">
          <h3>{transaction.description || transaction.counterparty.name}</h3>
          <h6 className="text-muted">
            on {new Date(transaction.date).toLocaleDateString()}
          </h6>
        </Col>
        <Col className="d-block d-lg-none text-end">
          <Button
            className={
              transaction.attachments.length == 1 ? "d-block" : "d-none"
            }
            variant="secondary"
            href={`${process.env.REACT_APP_STATIC_URL}/${transaction.attachments[0].path}`}
            target="_blank"
          >
            {transaction.attachments[0].description}
          </Button>
          <DropdownButton
            className={
              transaction.attachments.length == 1 ? "d-none" : "d-block"
            }
            as={ButtonGroup}
            title="Attachments"
            variant="secondary"
          >
            {transaction.attachments.map((attachment) => (
              <Dropdown.Item
                eventKey={attachment._id}
                key={attachment._id}
                href={`${process.env.REACT_APP_STATIC_URL}/${attachment.path}`}
                target="_blank"
              >
                {attachment.description}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
        <Col className="d-none d-lg-block text-end">
          <Button
            variant="secondary"
            onClick={(e) => setIsCommenting(!isCommenting)}
          >
            Comment
          </Button>{" "}
          <Button variant="primary" name="approve" onClick={handleSubmit}>
            Approve
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={7}>
          <Row xs={1} className="g-3">
            <Col>
              <Card>
                <Card.Body>
                  <Table borderless className="m-0">
                    <tbody>
                      <tr>
                        <td className="text-muted">Shop</td>
                        <td>{transaction.counterparty.name}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">Date</td>
                        <td>
                          {new Date(transaction.date).toLocaleDateString()}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-muted">Description</td>
                        <td>{transaction.description}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">Covered period</td>
                        <td>
                          {transaction.coverage
                            ? `${new Date(
                                transaction.coverage.start
                              ).toLocaleDateString()} to ${new Date(
                                transaction.coverage.end
                              ).toLocaleDateString()}`
                            : "(Not specified)"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>Categories</Card.Header>
                <Card.Body>
                  <Table borderless className="m-0">
                    <tbody>
                      {transaction.categories.map((category) => (
                        <tr key={category._id}>
                          <td>{category.category.name}</td>
                          <td>£{category.amount}</td>
                        </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td className="fw-bold">
                          £
                          {transaction.categories
                            .map((category) => category.amount)
                            .reduce((a, b) => a + b)
                            .toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>Splittings</Card.Header>
                <Card.Body>
                  <Table borderless className="m-0">
                    <tbody>
                      {transaction.splitting.splits.map((split) => (
                        <tr key={split._id}>
                          <td>{split.user.name}</td>
                          <td>
                            {transaction.splitting.by == "amount" ? "£" : ""}
                            {split.value}
                            {transaction.splitting.by == "percent" ? "%" : ""}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>Payments</Card.Header>
                <Card.Body>
                  <Table borderless className="m-0">
                    <tbody>
                      {transaction.payments.map((payment) => (
                        <tr key={payment._id}>
                          <td>{paymentUser(payment.paymentCard)}</td>
                          <td>£{payment.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col className={isCommenting ? "d-block d-lg-none" : "d-none"}>
              <Card>
                <Card.Body>
                  <Form className="d-grid">
                    <Form.Group
                      className="mb-3"
                      controlId="comment-textarea-xs"
                    >
                      <Form.Label>Leave a comment</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={comment || ""}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </Form.Group>
                    <Button name="comment" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4} className="d-lg-none d-grid">
              <Button
                variant="secondary"
                onClick={(e) => setIsCommenting(!isCommenting)}
              >
                Comment
              </Button>
            </Col>
            <Col xs={8} className="d-lg-none d-grid">
              <Button variant="primary" name="approve" onClick={handleSubmit}>
                Approve
              </Button>
            </Col>
          </Row>
        </Col>
        <Col lg={5} className="d-none d-lg-block">
          <Row className="g-3">
            <Col className={isCommenting ? "d-block" : "d-none"}>
              <Card>
                <Card.Body>
                  <Form className="d-grid">
                    <Form.Group
                      className="mb-3"
                      controlId="comment-textarea-lg"
                    >
                      <Form.Label>Leave a comment</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={comment || ""}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </Form.Group>
                    <Button name="comment" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col className={isCommenting ? "d-none" : "d-block"}>
              <Card>
                <Card.Body>
                  <Tabs id="attachments-tabs">
                    {transaction.attachments.map((attachment) => (
                      <Tab
                        key={attachment._id}
                        eventKey={attachment._id}
                        title={attachment.description}
                      >
                        <iframe
                          height="600"
                          width="100%"
                          src={`${process.env.REACT_APP_STATIC_URL}/${attachment.path}`}
                        ></iframe>
                      </Tab>
                    ))}
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
