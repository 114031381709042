import React, { Component } from "react";

import { Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

class Footer extends Component {
  render() {
    return (
      <Row className="mt-5 justify-content-center">
        <Col xs="10" sm="8" md="6" lg="4">
          <Accordion defaultActiveKey="0">
            <Accordion.Item>
              <Accordion.Header>
                <div>
                  <div>
                    <strong>Version 0.7.0</strong>
                  </div>
                  <div>
                    <small>What's New</small>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                What's new in this version:
                <br />
                <ul>
                  <li>Support of transaction covered period</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    );
  }
}

export default Footer;
