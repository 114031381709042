import React, { Component, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

// TEMPORARY WORKAROUND
import { reviewUser } from "../../static";

export default function Reject() {
  const { transaction_id } = useParams();

  return (
    <div>
      <h2>Review</h2>
      <p>Please share the reason you reject</p>

      <form action={process.env.REACT_APP_API_URL + "/review"} method="post">
        <input type="hidden" name="approved" value="false" />
        <input type="hidden" name="user" value={reviewUser()} />
        <input type="hidden" name="transaction" value={transaction_id} />
        <textarea name="comment"></textarea>
        <br />
        <input type="submit" value="Reject" />
      </form>

      <div>
        <p>
          <small>_id: {transaction_id}</small>
        </p>
      </div>
    </div>
  );
}
