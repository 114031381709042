function paymentUser(paymentCard) {
  switch (paymentCard) {
    case "61e22695c49611001a5170fc":
      return "Dave (4990)";
      break;
      
      case "61e5e3bfa22631001a311dbf":
      return "Dave (9066)";
      break;
      
      case "61e8653ca22631001a311f10":
      return "Dave (Default/Cash)";
      break;
      
      case "61e864fca22631001a311f07":
      return "Tim (Default/Cash)";
      break;
      
      case "61fd3b1fa6d5990026971301":
      return "Dave (7170)";
      break;
      
      case "61fd3b3fa6d5990026971304":
      return "Dave (8460)";
      break;
      
      case "61fd3b4ea6d5990026971307":
      return "Tim (0234)";
      break;
      
      case "61fd3f84a6d599002697132c":
      return "Dave (0936)";
      break;
      
      case "61fd3f70a6d5990026971328":
      return "Tim (5200)";
      break;

    default:
      return "Unknown";
      break;
  }
}

function reviewUser() {
  return "61e226a4c49611001a517100";
}

export { paymentUser, reviewUser };
