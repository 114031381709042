import { BrowserRouter, Route, Switch } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import NavBar from "./components/nav/navbar";
import Footer from "./components/nav/footer";

import TransactionAdd from "./components/transaction/add_transaction";
import Review from "./components/review/review";
import ReviewList from "./components/review/review_list";
import Reject from "./components/review/reject";

function App() {
  return (
    <main className="bg-light">
      <NavBar />
      <Container className="py-5 px-4">
        <Row>
          <BrowserRouter>
            <Switch>
              <Route exact path="/transaction/add">
                <TransactionAdd />
              </Route>

              <Route exact path="/review/pending/">
                <ReviewList />
              </Route>

              <Route exact path="/review/:transaction_id">
                <Review />
              </Route>

              <Route exact path="/review/reject/:transaction_id">
                <Reject />
              </Route>

              <Route path="/">
                <ReviewList />
                {/* <h2>Coming soon...</h2>
                <h3>Please visit with the provided review link</h3> */}
              </Route>
            </Switch>
          </BrowserRouter>
        </Row>
        <Footer />
      </Container>
    </main>
  );
}

export default App;
